.main-content {
  width: 100%;
  /* Adjust the width as needed */
  max-width: 100%;
  /* Add this to prevent the content from exceeding the screen width */
  box-sizing: border-box;
  /* Include padding and border in the total width */
  margin: 0px;
  padding: 0px;

}

body {
  background: #0b1d2a;
  overflow-x: hidden;
}


.arrows {
  width: 60px;
  height: 72px;
  position: relative;
  left: 50%;
  margin-left: -30px;
}

.arrows path {
  stroke: #83b5d2;
  fill: transparent;
  stroke-width: 1px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

@-webkit-keyframes arrow

/*Safari and Chrome*/
  {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
  /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  /* Safari 和 Chrome */
}

.about {
  height: auto;
  width: auto;
  background-color: #ffffff;
  padding: 50px;

}

.projects {
  height: auto;
  width: auto;
  background-color: #eeeeeb;
  padding: 50px
}

.contacts {
  height: auto;
  width: auto;
  background-color: #ffffff;
  padding: 50px
}

.more {
  height: auto;
  width: auto;
  background-color: #ffffff;
  padding: 50px
}

.testimonial {
  height: auto;
  width: auto;
  background-color: #e1e3e3;
  padding: 50px;
  max-width: 100%;
  /* Adjust the max-width as needed */
  margin: 0 auto;
  /* Center the container horizontally */
}

.main-content {
  width: auto
}

.scroll-up {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  z-index: 1;
}


.skills {
  justify-content: center;
  margin-bottom: 20px;
}

.screenshots {
  margin-left: 150px;
  margin-bottom: 0px;
}

.project {
  margin-left: 650px;
  min-width: auto;
}

.testimonial-cards {
  z-index: 0;
  width: 1000px
}


@media (max-width: 1040px) {
  .project {
    margin-left: 400px;
    font-size: small;
  }

  .screenshots {
    margin-left: 100px;
    width: 250px;
    font-size: small;
  }

  .testimonial-cards {
    z-index: 0;
    width: auto;
  }
}


@media (max-width: 780px) {
  .project {
    margin-left: 220px;
    width: 300px;
    font-size: small;
  }

  .screenshots {
    margin-left: 0;
    width: 200px;
    font-size: small;
  }

  .testimonial-cards {
    z-index: 0;
    width: auto;
    font-size: small;
  }

  .skills {
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    display: grid;
    place-items: center;
  }
}



@media (max-width: 580px) {
  .project {
    margin-left: 100px;
    width: 300px;
    font-size: small;
  }

  .screenshots {
    margin-left: 0;
    width: 200px;
    font-size: small;

  }

  .testimonial-cards {
    z-index: 0;
    width: auto;
    font-size: small;
  }

  .skills {
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    display: grid;
    place-items: center;
  }
}

@media (max-width: 455px) {
  .project {
    margin-left: 0px;
    width: 250px;
    font-size: small;
  }

  .screenshots {
    margin-left: 0;
    width: 200px;
    font-size: small;
  }

  .testimonial-cards {
    z-index: 0;
    width: auto;
    font-size: x-small;
  }

  .skills {
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    display: grid;
    place-items: center;
  }
}




.testimonial-description {
  margin-left: 20px;
  margin-right: 20px;
}


.NavBar {
  z-index: 1;
}

/* SKILLS */
.image-wrapper {
  position: relative;
}

.image-wrapper img {
  display: block;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  overflow-x: scroll;
}

.image-wrapper .hide-text {
  opacity: 0;
  position: relative;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 0px;
  color: #393838;
  text-decoration: none;
  text-align: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  -webkit-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  transition: opacity 500ms;

}

.image-wrapper:hover .hide-text {
  opacity: 0.8;
}

/* SOCIALS */
.social-wrapper {
  position: relative;
  height: fit-content;
  display: block;
}

.social-wrapper img {
  display: block;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
}

.social-wrapper .hide-social {
  opacity: 0;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 0px;
  color: #393838;
  text-decoration: none;
  text-align: center;
  font-size: xx-small;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  -webkit-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  transition: opacity 500ms;
}

.social-wrapper:hover .hide-social {
  opacity: 0.8;
}

.contact-card {
  width: 80%;
  justify-content: center;
  text-align: center;
}

.email-form {
  max-width: 700px;
}